



















import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter } from 'client-website-ts-library/filters';
import { View } from 'client-website-ts-library/plugins';
import { ListingStatus } from 'client-website-ts-library/types';

import Listings from '../components/Listings.vue';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    Listings,
    ListingSearch,
  },
})

export default class PropertiesForSale extends Mixins(View) {
  private listingFilter: ListingFilter | null = null;

  private listingFilterForHighlighted: ListingFilter | null = null;

  handleFilter(filter: ListingFilter) {
    this.listingFilter = filter;
  }

  mounted() {
    this.listingFilterForHighlighted = new ListingFilter({
      Statuses: [
        ListingStatus.Current,
        ListingStatus.UnderContract,
      ],
    });
    this.listingFilterForHighlighted.IsHighlightListing = true;
  }
}
